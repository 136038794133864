import React from "react";

const Loader = ({color}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
      style={{ display: 'block', background: 'rgba(255, 255, 255, 0)' }}
      width="200"
      height="200"
      id="spinner"
    >
    <style>
    {
      `
      [id=spinner]:root {
        --dashoffset: 140.36;
      }
  
      [id=spinner],
      circle {
        animation: 2s ease-in-out infinite both;
        transform-origin: 50% 50%;
      }
      
      [id=spinner] {
        animation-name: root;
      }

      circle {
        stroke: {color};
        stroke-dasharray: 163.36;
        stroke-dashoffset: var(--dashoffset);
        animation-name: spinner;
        stroke-linecap: round;
      }
  
      @keyframes spinner {
        0%,
        25% {
          stroke-dashoffset: var(--dashoffset);
          transform: rotate(0);
        }

        50%,
        75% {
          stroke-dashoffset: 81.68;
          transform: rotate(45deg);
        }

        100% {
          stroke-dashoffset: var(--dashoffset);
          transform: rotate(360deg);
        }
      }
      
      @keyframes root {
        0% {
          transform: rotateZ(0deg);
        }
        100% {
          transform: rotateZ(360deg)
        }
      }
      `   
    }
    </style>
    <circle r="26" strokeWidth="8" fill="none" cy="50" cx="50"></circle>
  </svg>
  )
}

export default Loader;