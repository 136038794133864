import React from "react";

const DownloadCsv = ({headers, data, isHidden}) => {
  let payload = [];

  const head = headers.map((h) => {
    let hdr = h;

    if (Object.hasOwn(h,'props')) {
      hdr = h.props.children;
    }

    return hdr;
  });

  const rows = data.map((row) => {
    delete row.isin;
    return Object.values(row).join(',');
  });

  console.log(rows)
  payload[0] = head.join(',');
  payload = payload.concat(rows);
  payload = payload.join('\n');

  const csvdata = new Blob([payload], {type: 'text/csv'});
  const url = URL.createObjectURL(csvdata);

  return (
    <a href={url} hidden={isHidden} download='RandomStockPicks.page.csv'>Download as CSV</a>
  )
}

export default DownloadCsv;